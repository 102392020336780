import * as React from "react"
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import StickyBar from "../components/Campaign/StickyBar/StickyBar";
import Banner from "../components/Campaign/Banner/banner";
import BannerVideo from "../components/Campaign/Banner/bannerVideo";
import EventModule from "../components/Campaign/EventModule/EventModule";
import SEO from "../components/seo";
import RegisterYourIntrest from "../components/Campaign/RegisterYourIntrest/RegisterYourIntrest";

const AboutModule = loadable(() => import("../components/Campaign/AboutModule/AboutModule"));
const InvestModule = loadable(() => import("../components/Campaign/InvestModule/InvestModule"));
const VideoModule = loadable(() => import("../components/Campaign/VideoModule/VideoModule"));
const LocationModule = loadable(() => import("../components/Campaign/LocationModule/LocationModule"));
const UnitTypesModule = loadable(() => import("../components/Campaign/UnitTypesModule/UnitTypesModule"));
const AmenitiesModule = loadable(() => import("../components/Campaign/AmenitiesModule/AmenitiesModule"));
const BrochureModule = loadable(() => import("../components/Campaign/BrochureModule/BrochureModule"));
const GalleryModule = loadable(() => import("../components/Campaign/GalleryModule/GalleryModule"));
const ContactModule = loadable(() => import("../components/Campaign/ContactModule/ContactModule"));
const BlockModule = loadable(() => import("../components/Campaign/BlockModule/BlockModule"));
const CampaignTemplate = ({ data }, props) => {
  const campaign = data?.strapiCampaign  
  const siteData = data?.strapiSiteConfig
  const [renderComponent, setRenderComponent] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, [])
  return (
    <Layout data={campaign}>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQTLZCNC"
        height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}

      <div className={`campaign ${campaign.Custom_CSS}`}>
        {campaign?.Banner_Type && campaign?.Banner_Type === "video" ?
          <BannerVideo {...campaign} /> : <Banner {...campaign} />
        }
        {campaign?.Register_Form &&
          <RegisterYourIntrest {...campaign} />
        }
        <StickyBar {...campaign} />
        {campaign.Add_Campaign_Module && campaign.Add_Campaign_Module.map((module, k) => {
          return (
            <div key={k}>
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_ABOUT_MODULE" &&
                <AboutModule {...module} />
              }
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_INVEST" &&
                <InvestModule {...module} />
              }
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_VIDEO" &&
                <VideoModule {...module} strapi_id={campaign.strapi_id} imagetransforms={campaign.imagetransforms} />
              }
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_UNIT_MODULE" &&
                <UnitTypesModule {...module} campaignType={campaign.Campaign_type} strapi_id={campaign.strapi_id} imagetransforms={campaign.imagetransforms} />
              }
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_LOCATION" &&
                <LocationModule {...module} />
              }
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_AMENITIES" &&
                <AmenitiesModule {...module} />
              }
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_BROUCHER" &&
                <BrochureModule {...module} strapi_id={campaign.strapi_id} imagetransforms={campaign.imagetransforms} />
              }
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_GALLERY" &&
                <GalleryModule {...module} strapi_id={campaign.strapi_id} imagetransforms={campaign.imagetransforms} />
              }
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_REGISTER_FORM" &&
                <ContactModule {...module} footer_data={siteData?.footer_project_license_details}/>
              }
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_BLOCK" &&
                <BlockModule {...module} />
              }
            </div>
          )
        })}
        {campaign.Show_Event &&
          <EventModule {...campaign} />
        }

      </div >
    </Layout >
  )
}

export const Head = ({ data }, props) => {
  const campaign = data?.strapiCampaign
  const siteData = data?.strapiSiteConfig

  var schemaSameAs = ''
  if (siteData?.facebook_link.length > 1) {
    schemaSameAs = siteData?.facebook_link + ','
  }
  if (siteData?.twitter_link.length > 1) {
    schemaSameAs += siteData?.twitter_link + ','
  }
  if (siteData?.instagram_link.length > 1) {
    schemaSameAs += siteData?.instagram_link + ','
  }
  if (siteData?.linkedin_link.length > 1) {
    schemaSameAs += siteData?.linkedin_link + ','
  }

  var ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": process.env.GATSBY_SITE_URL,
    "name": process.env.GATSBY_SITE_NAME,
    "alternateName": process.env.GATSBY_SITE_NAME,
    "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
    "sameAs": schemaSameAs.replace(/,\s*$/, "").split(',')
  };

  return (
    <SEO title={campaign.Meta_Title} description={campaign.Meta_Description}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />

    </SEO>
  )
}

export default CampaignTemplate;

export const query = graphql`
  query ($campaign_id: Int) {
    strapiCampaign(strapi_id: {eq: $campaign_id}) {
      ...CampaignFragment
      Add_Campaign_Module {
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_ABOUT_MODULE {
          ...CampaignAboutFragment
        }
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_LOCATION { 
         ...CampaignLocationFragment
        }
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_UNIT_MODULE {
          ...CampaignUnitsFragment 
        }
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_AMENITIES {
          ...CampaignAmenitiesFragment 
        }
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_GALLERY {
          ...CampaignGalleryFragment
        }
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_INVEST {
          ...CampaignInvestFragment 
        }
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_BROUCHER { 
          ...CampaignBroucherFragment
        }
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_VIDEO {
          ...CampaignVideoFragment
        } 
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_REGISTER_FORM {
         ...CampaignRegisterFormFragment
        } 
        ... on STRAPI__COMPONENT_CAMPAIGN_MODULES_BLOCK {
            ...CampaignBlockFragment
        }
      }
    } 
    strapiSiteConfig {
      twitter_link
      instagram_link
      linkedin_link
      facebook_link 
      footer_project_license_details {
        project_no
        license_no
        adm_no
      }
    }
  }
`
